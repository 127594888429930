export const environment = {
  production: false,
  authCookie: '_keymaker_token',
  appCookie: '_keymaker_app',
  roleCookie: '_keymaker_roles',
  domain: '.' + window.location.hostname.split('.').slice(-2).join('.'),
  appName: 'biaws',
  refreshPercentage: 0.5,
  tableItemPerPage: 20,
  tableMiniItemPerPage: 5,
  tableEnablePager: true,
  authHeader: 'X-Auth-Token',
  defaultLang: 'it',
  dateFormat: 'dd/MM/yyyy HH:mm:ss',
  simpleDateFormat: 'dd/MM/yyyy',
  changePasswordInterval: 7776000, // 3 mesi

  // SERVER DEV CONFIG
  apiBaseEndpoint: 'https://bia.test-securside.it/api/',
  authEndpoint: 'keymakerauthws/auth/',
  companyEndpoint: 'keymakeruserws/company/',
  companyLevelEndpoint: 'keymakeruserws/companylevel/',
  fileUserEndpoint: 'keymakeruserws/file/',
  userEndpoint: 'keymakeruserws/user/',
  roleEndpoint: 'keymakeruserws/role/',
  privilegeEndpoint: 'biaws/privilege/',
  archiveEndpoint: 'biaarchivews/archive/',
  applicationEndpoint: 'biaws/application/',
  fileEndpoint: 'biaws/file/',
  biaEndpoint: 'biaws/bia/',
  biaResultEndpoint: 'biaws/result/',
  biaItemEndpoint: 'biaws/biaitem/',
  complianceEndpoint: 'biaws/compliance/',
  preclassificationEndpoint: 'biaws/preclassification/',
  surveyEndpoint: 'biaws/survey/',
  questionEndpoint: 'biaws/question/',
  responseInstanceEndpoint: 'biaws/response_instance/',
  refreshTokenEndpoint: 'keymakerauthws/auth/refresh',
  reportEndpoint: 'biareportws/report/',
  dashboardEndpoint: 'biaws/dashboard/',
  userImportEndpoint: 'keymakeruserws/user_import/',
  infoEndpoint: 'biaws/managedinformation/',
  dpiacaseEndpoint: 'biaws/dpiacase/',
  templateEndpoint: 'biaws/template/',
  scopeEndpoint: 'biaws/scope/',
  reasonEndpoint: 'biaws/reason/',
  serviceTypeEndpoint: 'biaws/service_type/',
  configurationEndpoint: 'biaws/configuration/',
  responseEndpoint: 'biaws/response/',
  sectionEndpoint: 'biaws/section/',


};
