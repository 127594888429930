import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {Company} from '../models/company';
import {ApiResponse, Page} from '../models/apiresponse';
import {CompanyLevel, User} from '../models/user';
import 'rxjs-compat/add/observable/empty';
import 'rxjs-compat/add/observable/of';
import {map, tap} from 'rxjs/operators';
import {BatchImport} from '../models/batch-import';
import {Globals} from '../../globals';
import {NbAclService} from '../../security';

@Injectable()
export class UserApiService {

  companyUpdated: EventEmitter<string> = new EventEmitter();
  companyCreated: EventEmitter<string> = new EventEmitter();
  userUpdated: EventEmitter<string> = new EventEmitter();
  protected companyApiUrl: string = environment.apiBaseEndpoint + environment.companyEndpoint;
  protected companyLevelApiUrl: string = environment.apiBaseEndpoint + environment.companyLevelEndpoint;
  protected userApiUrl: string = environment.apiBaseEndpoint + environment.userEndpoint;
  private entityApiUrl: string = environment.apiBaseEndpoint + "/entity_list";
  protected roleApiUrl: string = environment.apiBaseEndpoint + environment.roleEndpoint;
  protected authApiUrl: string = environment.apiBaseEndpoint + environment.authEndpoint;
  protected privilegeApiUrl: string = environment.apiBaseEndpoint + environment.privilegeEndpoint;
  protected userImportApiUrl: string = environment.apiBaseEndpoint + environment.userImportEndpoint;
  protected fileUserEndpoint: string = environment.apiBaseEndpoint + environment.fileUserEndpoint;
  protected header = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(private http: HttpClient,
              private aclService: NbAclService,
              private globals: Globals) {
  }

  getUserListMethodByRole():string {
    if(this.aclService.canCurrent("USER_UPDATE") || this.aclService.canCurrent("USER_CREATE")) {
      return 'entity_list';
    } else {
      return 'user_list';
    }
  }

  /* USERS */
  resetPassword(username: string): Observable<any> {
    return this.http.put(this.authApiUrl + 'reset_password?username=' + encodeURI(username), null, {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.userUpdated.emit('password_reset');
          }
        }),
      );
  }

  getUser(uuid: string): Observable<any> {
    return this.http.get<ApiResponse<User>>(this.userApiUrl + 'retrieve_user?id=' + uuid + '&applicationEndpoint=' + environment.appName,
      {headers: this.header});
  }

  getUserInformation(uuid: string): Observable<any> {
    return this.http.get<ApiResponse<User>>(this.userApiUrl + 'retrieve_user_information?id=' + uuid + '&applicationEndpoint=' + environment.appName,
      {headers: this.header});
  }

  updateUser(user: User): Observable<any> {
    return this.http.put(this.userApiUrl + 'update?applicationEndpoint=' + environment.appName, JSON.stringify(user), {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.userUpdated.emit('user_updated');
          }
        }),
      );
  }

  uploadUsers(file: File): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    const req = new HttpRequest('POST', this.fileUserEndpoint + 'upload?applicationEndpoint=' + environment.appName, formdata, {
        reportProgress: true,
        responseType: 'json',
      },
    );
    return this.http.request(req);
  }

  importUsers(importJob: BatchImport): Observable<any> {
    return this.http.post(
      this.userImportApiUrl + 'import?applicationEndpoint='
      + environment.appName,
      JSON.stringify(importJob),
      {headers: this.header},
    );
  }

  exportUsers(): Observable<any> {
    return this.http.get(this.userImportApiUrl + 'export?applicationEndpoint='
      + environment.appName,
      {headers: this.header, responseType: 'blob' as 'blob', observe: 'response'});
  }

  getCurrentUserRoleAndPermissions(): Observable<any> {
    return this.http.get(this.privilegeApiUrl + 'get');
  }

  getPrivilegesMatrix(): Observable<any> {
    return this.http.get(this.privilegeApiUrl + 'privileges_roles_matrix');
  }

  updatePrivilege(value: boolean, idRole: number, idPrivilege: number): Observable<any> {
    const params = new HttpParams().set('idRole', idRole.toString()).set('idPrivilege', idPrivilege.toString());
    if (value)
      return this.http.post(this.privilegeApiUrl + 'assign', null, {params});
    else
      return this.http.delete(this.privilegeApiUrl + 'unassign', {params});
  }

  assignRole(idUser: number, idRole: number): Observable<any> {
    return this.http.post<ApiResponse<any>>(
      this.roleApiUrl + 'assign?idUser=' + idUser + '&idRole=' + idRole, null, {headers: this.header});
  }

  unassignRole(idUser: number, idRole: number): Observable<any> {
    return this.http.delete<ApiResponse<any>>(
      this.roleApiUrl + 'unassign?idUser=' + idUser + '&idRole=' + idRole, {headers: this.header});
  }

  updateRole(idUser: number, idRole: number): Observable<any> {
    return this.http.post<ApiResponse<any>>(
      this.roleApiUrl + 'update?idUser=' + idUser + '&idRole=' + idRole, null, {headers: this.header});
  }

  /* COMPANY*/
  getCompanyList(page: number, size: number, status?: number): Observable<any> {
    let params = new HttpParams().set('page', page.toString()).set('size', size.toString());
    if (status !== null && status !== undefined) {
      params = params.set('status', status.toString());
    }
    return this.http.get<ApiResponse<Page<Company>>>(this.companyApiUrl + 'company_list',
      {params, headers: this.header});
  }

  getCompany(id: any): Observable<any> {
    return this.http.get<ApiResponse<Company>>(this.companyApiUrl + 'retrieve/' + id, {headers: this.header});
  }

  updateCompany(company: Company): Observable<any> {
    return this.http.put(this.companyApiUrl + 'update', JSON.stringify(company), {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.companyUpdated.emit('company_updated');
          }
        }),
      );
  }

  createCompany(company: Company): Observable<any> {
    return this.http.post(
      this.companyApiUrl + 'create?applicationEndpoint=' + environment.appName,
      JSON.stringify(company),
      {headers: this.header},
    ).pipe(
      tap(res => {
        if (res.status.id === 0) {
          this.companyCreated.emit('company_created');
        }
      }),
    );
  }

  createUser(user: User): Observable<any> {
    user.currentApplicationEndpoint = environment.appName;
    return this.http.post(
      this.userApiUrl + 'create',
      JSON.stringify(user),
      {headers: this.header},
    );
  }

  assignCompany(idCompany: number): Observable<any> {
    return this.http.post(
      this.companyApiUrl + 'assign?idCompany=' + idCompany + '&active=true&applicationEndpoint=' + environment.appName,
      null,
      {headers: this.header}
    )
  }

  searchUsersByFullname(keyword: string = '', page: number, size: number, roleId?: number): Observable<any> {
    let params = new HttpParams().set('name', keyword)
      .set('applicationEndpoint', environment.appName)
      .set('page', page.toString())
      .set('size', size.toString());

    if (roleId > 0) {
      params = params.set('roleId', roleId.toString())
    }

    return this.http.get<ApiResponse<Page<User>>>(this.userApiUrl + 'user_list_by_name_surname', {
      params,
      headers: this.header,
    });
  }

  public requestAutocompleteCompanyLevel = (text: string): Observable<Response> => {
    return this.searchCompanyLevel(text, 0, this.globals.MAX_ELEMENT_AUTOCOMPLETE).pipe(
      map(res => res.data.content));
  }

  public companyLevelMatching = (value: string, target: CompanyLevel): boolean => {
    const re = new RegExp(value, 'i');
    return ((target.code.search(re) >= 0) || (target.description !== null && target.description.search(re) >= 0));
  }

  public userMatching = (value: string, target: CompanyLevel): boolean => {
    return true;
  }

  searchCompanyLevel(keyword: string = '', page: number, size: number): Observable<any> {
    const params = new HttpParams().set('name', keyword.toString())
      .set('codeOrDescription', keyword.toString())
      .set('page', page.toString())
      .set('size', size.toString());

    return this.http.get<ApiResponse<Page<User>>>(this.companyLevelApiUrl + 'list', {
      params,
      headers: this.header,
    });
  }

  public requestAutocompleteUsersWithRole = (text: string, roleId: number): Observable<Response> => {
    return this.searchUsersByFullname(text, 0, this.globals.MAX_ELEMENT_AUTOCOMPLETE, roleId).pipe(
      map(res => res.data.content.map((item: User) => {
        return {display: item.name + ' ' + item.surname, value: item.id};
      })),
    );
  };

  public requestAutocompleteUsers = (text: string): Observable<Response> => {
    return this.searchUsersByFullname(text, 0, this.globals.MAX_ELEMENT_AUTOCOMPLETE).pipe(
      map(res => res.data.content.map((item: User) => {
        return {display: item.name + ' ' + item.surname, value: item.id};
      }))
    );
  };

}
