import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {ApiResponse, Page} from '../models/apiresponse';
import 'rxjs-compat/add/observable/empty';
import 'rxjs-compat/add/observable/of';
import {map, tap} from 'rxjs/operators';
import {Survey} from '../models/survey';
import {Question, ResponseInstanceNote, ResponseQuestion} from '../models/question';
import {DashboardStatus} from '../models/dashboard-status';
import {Application} from '../models/application';
import {Bia, Biaitem, Compliance, Preclassification} from '../models/bia';
import {BatchImport} from '../models/batch-import';
import {Globals} from '../../globals';
import {InfoManaged} from '../models/info-managed';
import {DisasterRecovery} from '../models/disaster-recovery';
import {DpiaCase} from '../models/dpia-case';
import {Template} from '../models/template';
import {ServiceType} from '../models/service-type';
import {ConfigItem} from '../models/config-item';
import {User} from '../models/user';
import {Coefficient} from '../models/coefficient';

@Injectable()
export class SurveyApiService {

  surveyUpdated: EventEmitter<string> = new EventEmitter();
  surveyCreated: EventEmitter<string> = new EventEmitter();
  questionUpdated: EventEmitter<string> = new EventEmitter();
  questionCreated: EventEmitter<string> = new EventEmitter();
  biaCreated: EventEmitter<string> = new EventEmitter();
  biaUpdated: EventEmitter<string> = new EventEmitter();
  biaitemCreated: EventEmitter<string> = new EventEmitter();
  biaitemUpdated: EventEmitter<string> = new EventEmitter();
  biaitemDeleted: EventEmitter<string> = new EventEmitter();
  responseUpdated: EventEmitter<string> = new EventEmitter();
  responseCreated: EventEmitter<string> = new EventEmitter();
  applicationUpdated: EventEmitter<string> = new EventEmitter();
  infoManagedUpdated: EventEmitter<string> = new EventEmitter();
  infoManagedCreated: EventEmitter<string> = new EventEmitter();
  dpiacaseCreated: EventEmitter<string> = new EventEmitter();
  dpiacaseUpdated: EventEmitter<string> = new EventEmitter();
  templateUpdated: EventEmitter<string> = new EventEmitter();
  serviceTypeUpdated: EventEmitter<string> = new EventEmitter();
  configurationUpdated: EventEmitter<string> = new EventEmitter();
  protected biaApiUrl: string = environment.apiBaseEndpoint + environment.biaEndpoint;
  protected biaResultApiUrl: string = environment.apiBaseEndpoint + environment.biaResultEndpoint;
  protected surveyApiUrl: string = environment.apiBaseEndpoint + environment.surveyEndpoint;
  protected biaItemApiUrl: string = environment.apiBaseEndpoint + environment.biaItemEndpoint;
  protected infoApiUrl: string = environment.apiBaseEndpoint + environment.infoEndpoint;
  protected complianceApiUrl: string = environment.apiBaseEndpoint + environment.complianceEndpoint;
  protected preclassificationApiUrl: string = environment.apiBaseEndpoint + environment.preclassificationEndpoint;
  protected questionApiUrl: string = environment.apiBaseEndpoint + environment.questionEndpoint;
  protected responseInstanceApiUrl: string = environment.apiBaseEndpoint + environment.responseInstanceEndpoint;
  protected responseApiUrl: string = environment.apiBaseEndpoint + environment.responseEndpoint;
  protected dashboardApiUrl: string = environment.apiBaseEndpoint + environment.dashboardEndpoint;
  protected applicationApiUrl: string = environment.apiBaseEndpoint + environment.applicationEndpoint;
  protected fileApiUrl: string = environment.apiBaseEndpoint + environment.fileEndpoint;
  protected dpiacaseApiUrl: string = environment.apiBaseEndpoint + environment.dpiacaseEndpoint;
  protected templateApiUrl: string = environment.apiBaseEndpoint + environment.templateEndpoint;
  protected serviceTypeApiUrl: string = environment.apiBaseEndpoint + environment.serviceTypeEndpoint;
  protected configurationApiUrl: string = environment.apiBaseEndpoint + environment.configurationEndpoint;
  protected scopeApiUrl: string = environment.apiBaseEndpoint + environment.scopeEndpoint;
  protected reasonApiUrl: string = environment.apiBaseEndpoint + environment.reasonEndpoint;
  protected sectionApiUrl: string = environment.apiBaseEndpoint + environment.sectionEndpoint;
  protected header = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(private http: HttpClient, public globals: Globals) {
  }

  getLayers(): number[] {
    return [1, 2, 3];
  }

  getSurveyList(page: number, size: number): Observable<any> {
    const params = new HttpParams().set('page', page.toString()).set('size', size.toString());
    return this.http.get<ApiResponse<Page<Survey>>>(this.surveyApiUrl + 'list',
      {params, headers: this.header});
  }

  getQuestionRights(surveyInstanceId: number): Observable<any> {
    const params = new HttpParams().set('surveyInstanceId', surveyInstanceId.toString());
    return this.http.get(this.surveyApiUrl + 'question_rights',
      {params, headers: this.header});
  }

  getQuestionList(surveyId: number, sectionId?: number, serviceTypeId?: number): Observable<any> {
    let params = new HttpParams()
      .set('surveyId', surveyId.toString());
    if (sectionId > 0) {
      params = params.set('sectionId', sectionId.toString());
    }
    if (serviceTypeId > 0) {
      params = params.set('serviceTypeId', serviceTypeId.toString());
    }
    return this.http.get<ApiResponse<Question[]>>(this.questionApiUrl + 'list',
      {params, headers: this.header});
  }

  getResponseInstanceList(surveyId: number): Observable<any> {
    return this.http.get(this.responseInstanceApiUrl + 'list/' + surveyId,
      {headers: this.header});
  }

  searchSurvey(term: string, page: number, size: number, green: string, status?: number): Observable<any> {
    let params = new HttpParams().set('page', page.toString())
      .set('size', size.toString()).set('title', term).set('green', green);
    if (status !== null && status !== undefined)
      params = params.set('status', status.toString());
    return this.http.get<ApiResponse<Page<Survey>>>(this.surveyApiUrl + 'list',
      {params, headers: this.header});
  }

  createSurvey(survey: Survey): Observable<any> {
    return this.http.post(
      this.surveyApiUrl + 'create',
      JSON.stringify(survey),
      {headers: this.header},
    ).pipe(
      tap(res => {
        if (res.status.id === 0) {
          this.surveyCreated.emit('survey_created');
        }
      }),
    );
  }

  updateSurvey(survey: Survey): Observable<any> {
    return this.http.put(this.surveyApiUrl + 'update', JSON.stringify(survey), {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.surveyUpdated.emit('survey_updated');
          }
        }),
      );
  }

  getSurveyDetail(surveyInstanceId: number, page: number, size: number,
                  questionText?: string, userFullname?: string): Observable<any> {

    let params = new HttpParams().set('page', page.toString())
      .set('size', size.toString());
    if (questionText)
      params = params.set('questionText', questionText);
    if (userFullname)
      params = params.set('userFullname', userFullname);

    return this.http.get(this.surveyApiUrl + 'report_detail/' + surveyInstanceId,
      {params, headers: this.header});
  }

  getActiveBiaItemSurvey(): Observable<any> {
    const params = new HttpParams().set('page', '0').set('type', this.globals.SURVEY_TYPE_INFO.toString())
      .set('size', '1').set('status', this.globals.SURVEY_MODE_PUBLISHED.toString());
    return this.http.get(this.surveyApiUrl + 'list/',
      {params, headers: this.header});
  }


  public applicationMatching = (value: string, target: Application): boolean => {
    const re = new RegExp(value, 'i');
    return ((target.code.search(re) >= 0) || target.name.search(re) >= 0);
  }

  createOrUpdateQuestion(question: Question): Observable<any> {
    if (question.id) {
      return this.updateQuestion(question);
    } else {
      return this.createQuestion(question);
    }
  }

  createQuestion(question: Question): Observable<any> {
    return this.http.post(
      this.questionApiUrl + 'create',
      JSON.stringify(question),
      {headers: this.header},
    ).pipe(
      tap(res => {
        if (res.status.id === 0) {
          this.questionCreated.emit('question_created');
        }
      }),
    );
  }

  createOrUpdateResponseInstanceNote(responses: ResponseInstanceNote, update: boolean, recursive: boolean): Observable<any> {
    if (update) {
      return this.updateResponseInstanceNote(responses, recursive);
    } else {
      return this.createResponseInstanceNote(responses, recursive);
    }
  }

  createResponseInstanceNote(responses: ResponseInstanceNote, recursive: boolean): Observable<any> {
    return this.http.post(
      this.responseInstanceApiUrl + 'create?recursive=' + recursive,
      JSON.stringify(responses),
      {headers: this.header},
    ).pipe(
      tap(res => {
        if (res.status.id === 0) {
          this.responseCreated.emit('response_instance_created');
        }
      }),
    );
  }

  updateResponseInstanceNote(responses: ResponseInstanceNote, recursive: boolean): Observable<any> {
    return this.http.put(this.responseInstanceApiUrl + 'update', JSON.stringify(responses), {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.responseUpdated.emit('response_instance_updated');
          }
        }),
      );
  }

  updateProgressSurveyInstance(idSurvey: number, progress: number | string): Observable<any> {
    return this.http.put(this.surveyApiUrl + 'update_progress?surveyInstanceId=' + idSurvey + '&progress=' + progress,
      null, {headers: this.header});
  }

  updateQuestion(question: Question): Observable<any> {
    // console.log(JSON.stringify(question));
    return this.http.put(this.questionApiUrl + 'update', JSON.stringify(question), {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.questionUpdated.emit('question_updated');
          }
        }),
      );
  }

  updateQuestionPosition(question: Question): Observable<any> {
    const params = new HttpParams()
      .set('idQuestion', question.id.toString())
      .set('position', question.position.toString())
    return this.http.put(this.questionApiUrl + 'change_position', null, {params: params, headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.questionUpdated.emit('question_position_updated');
          }
        }),
      );
  }

  exportSurvey(id: number): Observable<any> {
    return this.http.get(this.surveyApiUrl + 'export/' + id,
      {headers: this.header, responseType: 'json', observe: 'response'});
  }

  importSurvey(file: File, type: number): Observable<any> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    formdata.append('type', type.toString());
    const req = new HttpRequest('POST', this.surveyApiUrl + 'import', formdata, {
        reportProgress: true,
      },
    );
    return this.http.request(req);
  }

  getSurvey(id: any): Observable<any> {
    return this.http.get<ApiResponse<Survey>>(this.surveyApiUrl + 'retrieve/' + id, {headers: this.header});
  }

  deleteQuestion(id: number): Observable<any> {
    return this.http.delete<ApiResponse<Question>>(this.questionApiUrl + 'delete/' + id, {headers: this.header});
  }

  checkCompleteSurvey(id: any): Observable<any> {
    return this.http.post(this.surveyApiUrl + 'check_complete?surveyInstanceId=' + id, null, {headers: this.header});
  }


  getDashboardStatus(): Observable<any> {
    return this.http.get<ApiResponse<DashboardStatus>>(this.dashboardApiUrl + 'status', {headers: this.header});
  }

  getBia(id: any): Observable<any> {
    return this.http.get<ApiResponse<Bia>>(this.biaApiUrl + 'retrieve/' + id, {headers: this.header});
  }

  getBiaResults(id: any): Observable<any> {
    return this.http.get<ApiResponse<any>>(this.biaResultApiUrl + 'retrieve/' + id, {headers: this.header});
  }

  getBiaResultSummary(id: any): Observable<any> {
    return this.http.get<ApiResponse<any>>(this.biaResultApiUrl + 'summary/' + id, {headers: this.header});
  }

  getBiaitem(id: any): Observable<any> {
    return this.http.get<ApiResponse<Biaitem>>(this.biaItemApiUrl + 'retrieve/' + id, {headers: this.header});
  }

  cloneBia(id: any): Observable<any> {
    return this.http.post(this.biaApiUrl + 'clone/' + id, null, {headers: this.header});
  }

  cloneBiaItem(id: any): Observable<any> {
    return this.http.post(this.biaItemApiUrl + 'clone/' + id, null, {headers: this.header});
  }

  validateBia(id: any, biaCode: string): Observable<any> {
    return this.http.put(this.biaApiUrl + 'validate', {id: id, biaCode: biaCode}, {headers: this.header}).pipe(
      tap(res => {
        if (res.status.id === 0) {
          this.biaUpdated.emit('bia_updated');
        }
      }),
    );
  }

  deleteBiaitem(id: any): Observable<any> {
    return this.http.delete<ApiResponse<Biaitem>>(this.biaItemApiUrl + 'delete/' + id, {headers: this.header}).pipe(
      tap(res => {
        if (res.status.id === 0) {
          this.biaitemDeleted.emit('biaitem_deleted');
        }
      }),
    );
    ;
  }

  getComplianceList(): Observable<any> {
    return this.http.get<ApiResponse<Compliance[]>>(this.complianceApiUrl + 'list',
      {headers: this.header});
  }

  getPreclassificationList(): Observable<any> {
    return this.http.get<ApiResponse<Preclassification[]>>(this.preclassificationApiUrl + 'list',
      {headers: this.header});
  }

  createBia(bia: Bia): Observable<any> {
    return this.http.post(
      this.biaApiUrl + 'create',
      JSON.stringify(bia),
      {headers: this.header},
    ).pipe(
      tap(res => {
        if (res.status.id === 0) {
          this.biaCreated.emit('bia_created');
        }
      }),
    );
  }

  updateBia(bia: Bia): Observable<any> {
    return this.http.put(this.biaApiUrl + 'update', JSON.stringify(bia), {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.biaUpdated.emit('bia_updated');
          }
        }),
      );
  }

  completeBia(id: number): Observable<any> {
    return this.http.put(this.biaApiUrl + 'complete/' + id, null, {headers: this.header}).pipe(
      tap(res => {
        if (res.status.id === 0) {
          this.biaUpdated.emit('bia_updated');
        }
      }),
    );
  }

  createBiaitem(biaitem: Bia): Observable<any> {
    return this.http.post(
      this.biaItemApiUrl + 'create',
      JSON.stringify(biaitem),
      {headers: this.header},
    ).pipe(
      tap(res => {
        if (res.status.id === 0) {
          this.biaitemCreated.emit('biaitem_created');
        }
      }),
    );
  }

  updateBiaitem(biaitem: Biaitem): Observable<any> {
    return this.http.put(this.biaItemApiUrl + 'update', JSON.stringify(biaitem), {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.biaitemUpdated.emit('biaitem_updated');
          }
        }),
      );
  }


  listBiaItems(page: number, size: number, idBia: number): Observable<any> {
    const params = new HttpParams().set('idBia', idBia.toString()).set('page', page.toString()).set('size', size.toString());
    return this.http.get<ApiResponse<Page<Biaitem>>>(this.biaItemApiUrl + 'list',
      {params, headers: this.header});
  }

  uploadImportFile(file: File): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    const req = new HttpRequest('POST', this.fileApiUrl + 'upload', formdata, {
        reportProgress: true,
        responseType: 'json',
      }
    );
    return this.http.request(req);
  }

  importApplications(importJob: BatchImport): Observable<any> {
    return this.http.post(
      this.applicationApiUrl + 'import',
      JSON.stringify(importJob),
      {headers: this.header}
    );
  }

  exportApplication(): Observable<any> {
    return this.http.get(this.applicationApiUrl + 'export',
      {headers: this.header, responseType: 'blob' as 'blob', observe: 'response'});
  }

  getApplicationList(page: number, size: number): Observable<any> {
    const params = new HttpParams().set('page', page.toString()).set('size', size.toString());
    return this.http.get<ApiResponse<Page<Application>>>(this.applicationApiUrl + 'application_list',
      {params, headers: this.header});
  }

  searchApplication(term: string, page: number, size: number, status?: number, idCampaign?: number, nameOrCode?: boolean): Observable<any> {
    let params = new HttpParams().set('page', page.toString()).set('size', size.toString());
    params = (nameOrCode) ? params.set('nameOrCode', term) : params.set('nameOrCode', term);
    if (status !== null && status !== undefined) {
      params = params.set('status', status.toString());
    }
    if (idCampaign > 0) {
      params = params.set('idCampaign', idCampaign.toString());
    }
    return this.http.get<ApiResponse<Page<Application>>>(this.applicationApiUrl + 'application_list',
      {params, headers: this.header});

  }

  public requestAutocompleteApplication = (text: string): Observable<Response> => {
    return this.searchApplication(text, 0, this.globals.MAX_ELEMENT_AUTOCOMPLETE, this.globals.APP_STATUS_ACTIVE, null, true).pipe(
      map(res => res.data.content)
    );
  };

  updateApplication(application: Application): Observable<any> {
    return this.http.put(this.applicationApiUrl + 'update', JSON.stringify(application), {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id == 0) {
            this.applicationUpdated.emit('application_updated');
          }
        })
      );
  }

  createApplication(application: Application): Observable<any> {
    return this.http.post(
      this.applicationApiUrl + 'create',
      JSON.stringify(application),
      {headers: this.header}
    );
  }

  createInfoManaged(info: InfoManaged): Observable<any> {
    return this.http.post(
      this.infoApiUrl + 'create',
      JSON.stringify(info),
      {headers: this.header},
    ).pipe(
      tap(res => {
        if (res.status.id === 0) {
          this.infoManagedCreated.emit('biaitem_created');
        }
      }),
    );
  }

  updateInfoManaged(info: InfoManaged): Observable<any> {
    return this.http.put(this.infoApiUrl + 'update', JSON.stringify(info), {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.infoManagedUpdated.emit('biaitem_updated');
          }
        }),
      );
  }

  listInfoManaged(page: number, size: number, idBia: number): Observable<any> {
    const params = new HttpParams().set('idBia', idBia.toString()).set('page', page.toString()).set('size', size.toString());
    return this.http.get<ApiResponse<Page<InfoManaged>>>(this.infoApiUrl + 'list',
      {params, headers: this.header});
  }

  updateDisasterRecovery(dr: DisasterRecovery): Observable<any> {
    return this.http.put(this.biaApiUrl + 'update_dr', JSON.stringify(dr), {headers: this.header});
  }

  retrieveDpiaCase(id: number): Observable<any> {
    return this.http.get<ApiResponse<any>>(this.dpiacaseApiUrl + 'retrieve/' + id,
      {headers: this.header});
  }

  deleteDpiaCase(id: number): Observable<any> {
    return this.http.delete(this.dpiacaseApiUrl + 'delete/' + id, {headers: this.header, observe: 'body'}).pipe(
      tap(res => {
        if (res.status.id === 0) {
          this.dpiacaseUpdated.emit('dpiacase_updated');
        }
      }),
    );
  }

  createDpiaCase(dpiaCase: DpiaCase): Observable<any> {
    return this.http.post(
      this.dpiacaseApiUrl + 'create',
      JSON.stringify(dpiaCase),
      {headers: this.header},
    ).pipe(
      tap(res => {
        if (res.status.id === 0) {
          this.dpiacaseCreated.emit('dpiacase_created');
        }
      }),
    );
  }

  updateDpiaCase(dpiaCase: DpiaCase): Observable<any> {
    return this.http.put(this.dpiacaseApiUrl + 'update', JSON.stringify(dpiaCase), {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.dpiacaseUpdated.emit('dpiacase_updated');
          }
        }),
      );
  }

  updateProjectStatus(idUser: number, idRole: number): Observable<any> {
    return this.http.put(this.dpiacaseApiUrl + 'update?idUser=' + idUser + '&idRole=' + idRole,
      null, {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.dpiacaseUpdated.emit('dpiacase_updated');
          }
        }),
      );
  }

  assignDpiaCaseResponse(idDpiaCase: number, idResponse: number): Observable<any> {
    return this.http.post(this.dpiacaseApiUrl + 'assign_response?idDpiaCase=' + idDpiaCase + '&idResponse=' + idResponse,
      null, {headers: this.header});
  }

  unassignDpiaCaseResponse(idDpiaCase: number, idResponse: number): Observable<any> {
    return this.http.delete(this.dpiacaseApiUrl + 'unassign_response?idDpiaCase=' + idDpiaCase + '&idResponse=' + idResponse,
      {headers: this.header});
  }

  getMatrixDpiaCaseResponse(): Observable<any> {
    return this.http.get<ApiResponse<any>>(this.dpiacaseApiUrl + 'matrix_response_dpiacase/',
      {headers: this.header});
  }

  getDpiaResult(idBia: number): Observable<any> {
    return this.http.get<ApiResponse<any>>(this.biaApiUrl + 'dpia_result/' + idBia,
      {headers: this.header});
  }


  getScopes(): Observable<any> {
    return this.http.get<ApiResponse<any>>(this.scopeApiUrl + 'list/',
      {headers: this.header});
  }

  getSections(): Observable<any> {
    return this.http.get<ApiResponse<any>>(this.sectionApiUrl + 'list/',
      {headers: this.header});
  }

  getReasons(): Observable<any> {
    return this.http.get<ApiResponse<any>>(this.reasonApiUrl + 'list/',
      {headers: this.header});
  }

  listTemplate(page: number, size: number): Observable<any> {
    const params = new HttpParams().set('page', page.toString()).set('size', size.toString());
    return this.http.get<ApiResponse<Page<Template>>>(this.templateApiUrl + 'list',
      {params, headers: this.header});
  }

  retrieveTemplate(id: number): Observable<any> {
    return this.http.get<ApiResponse<any>>(this.templateApiUrl + 'retrieve/' + id,
      {headers: this.header});
  }

  updateTemplate(template: Template): Observable<any> {
    return this.http.put(this.templateApiUrl + 'update', JSON.stringify(template), {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.templateUpdated.emit('template_updated');
          }
        }),
      );
  }

  changeBiaStatus(id: number, newStatus: number): Observable<any> {
    return this.http.put(this.biaApiUrl + 'change_state?id=' + id + '&status=' + newStatus,
      null, {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.biaUpdated.emit('bia_status_updated');
          }
        }),
      );
  }

  createServiceType(serviceType: ServiceType): Observable<any> {
    return this.http.post(
      this.serviceTypeApiUrl + 'create',
      JSON.stringify(serviceType),
      {headers: this.header},
    ).pipe(
      tap(res => {
        if (res.status.id === 0) {
          this.serviceTypeUpdated.emit('service_type_created');
        }
      }),
    );
  }

  updateServiceType(serviceType: ServiceType): Observable<any> {
    return this.http.put(this.serviceTypeApiUrl + 'update', JSON.stringify(serviceType), {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.serviceTypeUpdated.emit('service_type_updated');
          }
        }),
      );
  }


  updateConfig(configItem: ConfigItem): Observable<any> {
    return this.http.put(this.configurationApiUrl + 'update', JSON.stringify(configItem), {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.configurationUpdated.emit('config_updated');
          }
        }),
      );
  }

  searchServiceType(keyword: any, page: number, size: number, status?: number): Observable<any> {
    let params = new HttpParams()
      .set('name', keyword.toString())
      .set('page', page.toString())
      .set('size', size.toString());

    if (status !== null && status !== undefined) {
      params = params.set('status', status.toString());
    }

    return this.http.get<ApiResponse<Page<User>>>(this.serviceTypeApiUrl + 'list', {
      params,
      headers: this.header
    });
  }

  getConfiguration(): Observable<any> {
    const params = new HttpParams()
      .set('page', '0')
      .set('size', '100000');

    return this.http.get<ApiResponse<Page<ConfigItem>>>(this.configurationApiUrl + 'list', {
      params,
      headers: this.header
    });
  }

  saveReferenceToBia(idBiaItem: number): Observable<any> {
    return this.http.put(this.biaItemApiUrl + 'reference_to_bia?id=' + idBiaItem,
      null, {headers: this.header});
  }

  updateResponses(responses: ResponseQuestion[]): Observable<any> {
    return this.http.put(this.responseApiUrl + 'update',
      JSON.stringify(responses), {headers: this.header});
  }

  updateCoefficients(coefficients: Coefficient[]): Observable<any> {
    return this.http.put(this.responseApiUrl + 'update_coefficients',
      JSON.stringify(coefficients), {headers: this.header});
  }

}
