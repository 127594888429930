import {Injectable} from '@angular/core';

@Injectable()
export class PermissionService {

  private permissions: Set<string> = new Set<string>();
  private _currentUserUUID: string;
  private _currentUserRole: string;

  public getCurrentUserRole(): string {
    return this._currentUserRole;
  }

  public setCurrentUserRole(value: string) {
    this._currentUserRole = value;
  }


  public getCurrentUserUUID(): string {
    return this._currentUserUUID;
  }

  public setCurrentUserUUID(value: string) {
    this._currentUserUUID = value;
  }

  hasPermission(permission: string): boolean {
    return this.permissions.has(permission);
  }

  addPermission(permission: string): void {
    this.permissions.add(permission);
  }

  getPermissions(): string[] {
    return Array.from(this.permissions.values());
  }

  addAllPermissions(permissions: string[]): void {
    permissions.forEach(p => {
      this.permissions.add(p);
    })
  }

  removePermission(permission: string): void {
    this.permissions.delete(permission);
  }

  clear(): void {
    this.permissions.clear();
  }

}
