import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import 'rxjs-compat/add/observable/empty';
import 'rxjs-compat/add/observable/of';

@Injectable()
export class ReportApiService {

  protected reportApiUrl: string = environment.apiBaseEndpoint + environment.reportEndpoint;

  protected header = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'});


  constructor(private http: HttpClient) {
  }

  exportBia(id: number, type: string, full: boolean): Observable<any> {
    const method = full ? 'bia_complete/' : 'bia/';
    return this.http.get(this.reportApiUrl + method + id + '/' + type,
      {headers: this.header, responseType: 'blob' as 'blob', observe: 'response'});
  }


}
