import {Observable, of} from 'rxjs';
import {NbRoleProvider} from './role.provider';
import {Injectable} from '@angular/core';
import {PermissionService} from './permission.service';

@Injectable()
export class NbApiRoleProvider implements NbRoleProvider {

  constructor(private permissionService: PermissionService) {
  }

  // @ts-ignore
  getRole(): Observable<string | string[]> {
    const role: string = this.permissionService.getCurrentUserRole();;
    return of(role);
  }

}
