import {BodyOutputType, Toast, ToasterConfig} from 'angular2-toaster';

export class ToasterBuilder {

  public configTopCenter = new ToasterConfig({
    positionClass: 'toast-top-center',
    timeout: 5000,
    newestOnTop: true,
    tapToDismiss: true,
    preventDuplicates: true,
    animation: 'slideDown',
    limit: 5,
  });

  public configTopRight = new ToasterConfig({
    positionClass: 'toast-top-right',
    timeout: 5000,
    newestOnTop: true,
    tapToDismiss: true,
    preventDuplicates: true,
    animation: 'slideDown',
    limit: 5,
  });

  public configTopLeft = new ToasterConfig({
    positionClass: 'toast-top-left',
    timeout: 5000,
    newestOnTop: true,
    tapToDismiss: true,
    preventDuplicates: true,
    animation: 'slideDown',
    limit: 5,
  });

  //types: string[] = ['default', 'info', 'success', 'warning', 'error'];
  //animations: string[] = ['fade', 'flyLeft', 'flyRight', 'slideDown', 'slideUp'];
  //positions: string[] = ['toast-top-full-width', 'toast-bottom-full-width', 'toast-top-left', 'toast-top-center',
  //  'toast-top-right', 'toast-bottom-right', 'toast-bottom-center', 'toast-bottom-left', 'toast-center'];

  public get(type: string, title: string, body: string, timeout = 5000) {
    const toast: Toast = {
      type: type,
      title: title,
      body: body,
      timeout: timeout,
      showCloseButton: true,
      bodyOutputType: BodyOutputType.TrustedHtml,
    };
    return toast;

  }

}
