import {Inject, Injectable} from '@angular/core';

import {NbAuthJWTToken, NbAuthToken} from './token';
import {NbAuthTokenParceler} from './token-parceler';
import {NbAclService} from '../../../security/services/acl.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {environment} from '../../../../environments/environment';
import {NB_AUTH_OPTIONS} from '../../auth.options';
import {getDeepFromObject} from '../../helpers';
import {Globals} from '../../../globals';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {PermissionService} from '../../../security/services/permission.service';

export abstract class NbTokenStorage {

  abstract get(): NbAuthToken;

  abstract set(token: NbAuthToken);

  abstract clear();

  loadState(): Observable<boolean> {
    return of(true);
  };

  storeAclData(res: any) {
    return;
  };
}

/**
 * Service that uses browser localStorage as a storage.
 *
 * The token storage is provided into auth module the following way:
 * ```ts
 * { provide: NbTokenStorage, useClass: NbTokenLocalStorage },
 * ```
 *
 * If you need to change the storage behaviour or provide your own - just extend your class from basic `NbTokenStorage`
 * or `NbTokenLocalStorage` and provide in your `app.module`:
 * ```ts
 * { provide: NbTokenStorage, useClass: NbTokenCustomStorage },
 * ```
 *
 */
@Injectable()
export class NbTokenLocalStorage extends NbTokenStorage {

  protected key = 'auth_app_token';

  constructor(private parceler: NbAuthTokenParceler,
              private router: Router,
              private permissionProvider: PermissionService,
              private aclService: NbAclService) {
    super();
  }

  /**
   * Returns token from localStorage
   * @returns {NbAuthToken}
   */
  get(): NbAuthToken {
    const raw = localStorage.getItem(this.key);
    return this.parceler.unwrap(raw);
  }

  /**
   * Sets token to localStorage
   * @param {NbAuthToken} token
   */
  set(token: NbAuthToken) {
    const raw = this.parceler.wrap(token);
    localStorage.setItem(this.key, raw);
  }

  /**
   * Clears token from localStorage
   */
  clear() {
    localStorage.removeItem(this.key);
    this.permissionProvider.setCurrentUserRole(null);
  }

  loadState(): Observable<boolean> {
    if (this.aclService.isStateLoaded())
      return of(true);
    const permissions = this.permissionProvider.getPermissions();
    const roleName = this.permissionProvider.getCurrentUserRole();
    if (permissions == null || roleName == null) {
      // clean token, permission and return false for going to login
      this.clear();
      return of(false);
    } else {
      this.aclService.register(roleName, null, {});
      this.permissionProvider.setCurrentUserRole(roleName);
      permissions.forEach(p => {
        this.aclService.allow(roleName, p, '*');
      })
      // basic permission for authenticated users
      this.aclService.allow(roleName, 'AUTHENTICATED', '*');
      return of(true);
    }
  }

  storeAclData(res: any) {
    const roleName = res.body['data'].nameRole;
    const idRole = res.body['data'].idRole;
    const privileges = res.body['data'].privileges;
    const currentUserId = res.body['data'].id;
    this.permissionProvider.setCurrentUserRole(roleName);
    this.permissionProvider.addAllPermissions(privileges);
    this.loadState();
  }

}

@Injectable()
export class NbTokenCookieStorage extends NbTokenStorage {

  protected key = 'auth_app_token';

  constructor(private parceler: NbAuthTokenParceler,
              private router: Router,
              private http: HttpClient,
              private globals: Globals,
              @Inject(NB_AUTH_OPTIONS) protected options = {},
              private cookieService: CookieService,
              private permissionService: PermissionService,
              private aclService: NbAclService) {
    super();
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }

  /**
   * Returns token loaded from cookies
   * @returns {NbAuthToken}
   */
  get(): NbAuthToken {
    // Load the token from cookies, if it exists
    if (this.cookieService.check(environment.authCookie)) {
      const t = this.cookieService.get(environment.authCookie);
      const strategy = this.getConfigValue('forms.login.strategy');
      const token: NbAuthJWTToken = new NbAuthJWTToken(t, strategy);
      this.set(token);
      return token;
    }
    // Return token from local storage
    const raw = localStorage.getItem(this.key);
    return this.parceler.unwrap(raw);
  }

  /**
   * Sets token from cookies to local storage
   * @param {NbAuthToken} token
   */
  set(token: NbAuthJWTToken) {
    this.createCookies(token);
    const raw = this.parceler.wrap(token);
    localStorage.setItem(this.key, raw);
  }

  /**
   * Clears token from cookies and local storage
   */
  clear() {
    this.permissionService.clear();
    localStorage.removeItem(this.key);
    this.permissionService.setCurrentUserRole(null);
    this.cookieService.delete(environment.authCookie, '/', environment.domain);
    this.cookieService.delete(environment.appCookie, '/', environment.domain);
    this.cookieService.delete(environment.roleCookie, '/', environment.domain);
  }

  loadState(): Observable<boolean> {
    const privileges = this.permissionService.getPermissions();
    if (this.aclService.isStateLoaded() && privileges)
      return of(true);
    // Se arrivo qui sono autenticato, ma potrei non avere i permessi caricati
    let roleName = this.permissionService.getCurrentUserRole();
    if (roleName == null && this.cookieService.check(environment.roleCookie)) {
      const rolesCookie = this.cookieService.get(environment.roleCookie);
      const roles = JSON.parse(rolesCookie);
      const apps = JSON.parse(this.cookieService.get(environment.appCookie));
      const index = apps.findIndex(x => x === environment.appName);
      if (index >= 0) {
        roleName = roles[index];
        this.permissionService.setCurrentUserRole(roleName);
      }
    }

    if (privileges.length == 0) {
      return this.loadRemotePrivileges();
    } else {
      for (let i = 0; i < privileges.length; i++) {
        this.aclService.allow(roleName, privileges[i], '*');
      }
      // basic permission for authenticated users
      this.aclService.allow(roleName, 'AUTHENTICATED', '*');
      return of(true);
    }
  }

  private createCookies(token: NbAuthJWTToken) {
    let apps = [];
    let rolesInfo = '';
    if (token.getPayload() !== null) {
      apps = token.getPayload().application_endpoints.split(',');
      rolesInfo = token.getPayload().application_roles.split(',');
    }
    const roleIndex = apps.findIndex(e => e === environment.appName);
    let roleName = '';
    if (roleIndex >= 0) {
      roleName = rolesInfo[roleIndex];
    }
    this.permissionService.setCurrentUserRole(roleName);
    const expDate = token.getTokenExpDate();
    this.cookieService.set(environment.authCookie, token.getValue(),
      expDate, null, environment.domain, true, 'Lax');
    this.cookieService.set(environment.appCookie, JSON.stringify(apps),
      expDate, null, environment.domain, true, 'Lax');
    this.cookieService.set(environment.roleCookie, JSON.stringify(rolesInfo),
      expDate, null, environment.domain, true, 'Lax');
  }

  private loadRemotePrivileges(): Observable<boolean> {
    return this.http.get(environment.apiBaseEndpoint + environment.privilegeEndpoint + 'get').pipe(
      map((res: any) => {
        if (res.status.id === this.globals.HTTP_NOT_ERROR_STATUS_ID) {
          const roleName = res.data.role;
          const privileges = res.data.privileges;
          if (privileges) {
            this.permissionService.setCurrentUserRole(roleName);
            this.permissionService.addAllPermissions(privileges);
            for (let i = 0; i < privileges.length; i++) {
              this.aclService.allow(roleName, privileges[i], '*');
            }
            // basic permission for authenticated users
            this.aclService.allow(roleName, 'AUTHENTICATED', '*');
          }
          return true;
        } else {
          return false;
        }
      }))
  }

}
