import {Injectable} from '@angular/core';

@Injectable()
export class Globals {
  constructor() {
  }

  public BEAN_STATUS_NOT_ACTIVE: number = 0;
  public BEAN_STATUS_ACTIVE: number = 1;
  public BEAN_STATUS_CANCELLED: number = -2;

  public SURVEY_TYPE_BIA: number = 1;
  public SURVEY_TYPE_INFO: number = 0;

  public TEMPLATE_STATUS_NOT_ACTIVE: number = 0;
  public TEMPLATE_STATUS_ACTIVE: number = 1;

  public BIA_STATUS_CANCELLED: number = -2;
  public BIA_STATUS_DRAFT: number = 0;
  public BIA_STATUS_COMPLETED: number = 1;
  public BIA_STATUS_VALIDATED: number = 2;


  public APP_STATUS_ACTIVE: number = 1;
  public APP_STATUS_CANCELLED: number = -2;

  public HTTP_NOT_ERROR_STATUS_ID: number = 0;
  public HTTP_ERROR_STATUS_ID: number = 1;
  public COMPANY_STATUS_ACTIVE: number = 1;
  public COMPANY_STATUS_NOT_ACTIVE: number = 0;
  public COMPANY_STATUS_BLOCKED: number = -1;
  public COMPANY_STATUS_CANCELLED: number = -2;

  public USER_STATUS_ACTIVE: number = 1;
  public USER_STATUS_NOT_ACTIVE: number = 0;
  public USER_STATUS_BLOCKED: number = -1;
  public USER_STATUS_CANCELLED: number = -2;

  public BIA_SCOPES_MAP = new Map<number, string>([
    [1, 'Servizio/Prodotto'],
    [2, 'Piattaforma trasversale'],
  ]);

  public BIA_REASONS_MAP = new Map<number, string>([
    [1, 'Nuovo Servizio/Prodotto'],
    [2, 'Nuova Piattaforma trasversale'],
    [3, 'Evoluzione/Aggiornamento Piattaforma trasversale esistente'],
  ]);

  public BIA_HEATMAP_LEVELS = [
    {name: 'Basso', index: 0},
    {name: 'Medio-Basso', index: 1},
    {name: 'Medio', index: 2},
    {name: 'Medio-Alto', index: 3},
    {name: 'Alto', index: 4}
  ];

  public TEMPLATE_BIA_CREATED = 1;
  public TEMPLATE_BIA_STATUS_CHANGE_TO_COMPLETED = 2;
  public TEMPLATE_BIA_STATUS_CHANGE_TO_VALIDATED = 3;
  public TEMPLATE_BIA_COMPLIANCE_PERIMETERS = 4;
  public TEMPLATE_SCHEDULED = 5;
  public TEMPLATE_BIA_IMPACT_THRESHOLD = 6;

  public TEMPLATE_FREQUENCY_MONTHLY = 4;
  public TEMPLATE_FREQUENCY_WEEKLY = 3;

// ID ruoli su Keymaker
  public ROLE_ADMIN_ID = 23;
  public ROLE_DELEGATE_ID = 24;
  public ROLE_BUSINESS_ID = 25;
  public ROLE_SECURITY_ID = 26;
  public ROLE_BCMBP_ID = 27;
  public ROLE_UNDEFINED_ID = -1;

  public TEMPLATE_THRESHOLDS = [
    {key: "qualitativeImpact", name: "Impatto Qualitativo"},
    {key: "quantitativeImpact", name: "Impatto Quantitativo"},
    {key: "rto", name: "RTO"},
    {key: "rpo", name: "RPO"},
  ]


  public ROLE_ADMIN = "ROLE_ADMIN";
  public ROLE_DELEGATE = "ROLE_DELEGATE";
  public ROLE_BUSINESS = "ROLE_BUSINESS";
  public ROLE_SECURITY = "ROLE_SECURITY";
  public ROLE_BCMBP = "ROLE_BCMBP";
  public ROLE_UNDEFINED = "UNDEFINED";

  public ROLES = [
    {value: this.ROLE_ADMIN_ID, title: "Amministratore", key: this.ROLE_ADMIN},
    {value: this.ROLE_DELEGATE_ID, title: "Delegato", key: this.ROLE_DELEGATE},
    {value: this.ROLE_SECURITY_ID, title: "Sicurezza", key: this.ROLE_SECURITY},
    {value: this.ROLE_BUSINESS_ID, title: "Business", key: this.ROLE_BUSINESS},
    {value: this.ROLE_BCMBP_ID, title: "BCM Banco Posta", key: this.ROLE_BCMBP},
  ];

  public ROLE_IDS = new Map<string, number>([
    [this.ROLE_ADMIN, this.ROLE_ADMIN_ID],
    [this.ROLE_DELEGATE, this.ROLE_DELEGATE_ID],
    [this.ROLE_SECURITY, this.ROLE_SECURITY_ID],
    [this.ROLE_BUSINESS, this.ROLE_BUSINESS_ID],
    [this.ROLE_BCMBP, this.ROLE_BCMBP_ID],
  ]);

  public ROLE_IDS_KEYS = new Map<number, string>([
    [this.ROLE_ADMIN_ID, this.ROLE_ADMIN],
    [this.ROLE_DELEGATE_ID, this.ROLE_DELEGATE],
    [this.ROLE_SECURITY_ID, this.ROLE_SECURITY],
    [this.ROLE_BUSINESS_ID, this.ROLE_BUSINESS],
    [this.ROLE_BCMBP_ID, this.ROLE_BCMBP],
  ]);

  public MAX_ELEMENT_AUTOCOMPLETE: number = 5;

  public TYPE_QUESTION_TEXT: number = 1;
  public TYPE_QUESTION_MULTIPLE: number = 2;
  public TYPE_QUESTION_EXCLUSIVE: number = 3;

  /* Question type parameters */
  public QUESTION_TYPE_UNDEFINED: number = -1;
  public QUESTION_TYPE_FREE: number = 1;
  public QUESTION_TYPE_MULTIPLE_CHOICE: number = 2;
  public QUESTION_TYPE_MULTIPLE_CHOICE_EXCLUSIVE: number = 3;

  /* Survey type parameters */
  public SURVEY_MIN_TEXT_LENGTH: number = 2;
  public SURVEY_MODE_CANCELLED: number = -2;
  public SURVEY_MODE_DRAFT: number = 0;
  public SURVEY_MODE_PUBLISHED: number = 1;

  public SURVEY_TYPE_STATUS_ACTIVE: number = 1;
  public SURVEY_TYPE_STATUS_NOT_ACTIVE: number = 0;


}
